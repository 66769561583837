.modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  &.slideAway {
    pointer-events: none;
  }

  &_container {
    background: black;
    border-radius: 10px;
    box-shadow: 0 1rem 4rem rgba(black, 0.2);
    position: relative;
    margin: 0 3rem;
    overflow: hidden;

    animation: 1s slidefrombottom ease;
  }

  &.slideAway &_container {
    // animation: 1s slideAway ease;
    animation: 1s slidefrombottomReverse ease;
  }

  &_top {
    background: #63656b;

    height: 4rem;
    display: flex;
    align-items: center;
  }

  &_circle {
    margin-left: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
    border-radius: 50%;
  }

  &_circle_red {
    background-color: #ff5f57;
  }

  &_circle_yellow {
    background-color: #ffbd2e;
  }

  &_circle_green {
    background-color: #2ace42;
  }

  &_body {
    padding: 3rem 9rem 3rem 9rem;
    @include respond(phone-custom) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  &_close {
    height: 2.5rem;
    width: 2.5rem;

    .vanilla_item {
      fill: white;
      cursor: pointer;
    }
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;

    &:hover {
      .vanilla_item {
        fill: goldenrod;
      }
    }
  }

  &_title {
    font-weight: 800;
    display: inline-block;
    text-align: right;
    color: $color-grey-light-1;
    width: 9rem;
    padding-right: 2rem;

    @include respond(phone-custom) {
      width: initial;
      padding-right: 1rem;
    }
  }

  &_text {
    color: #fff;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    color: $color-tertiary-light;
    line-height: 1.7;
  }
}

@keyframes slidefrombottom {
  0% {
    transform: translateX(150%) scale(0.1);
    opacity: 0;
  }

  90% {
    transform: translateX(-2%);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slidefrombottomReverse {
  0% {
    transform: translateX(0%);
  }

  20% {
    transform: translateX(30%);
  }

  100% {
    transform: translateX(-150%) scale(0.1);
    opacity: 0;
  }
}
