.portfolio {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: linear-gradient(to right, #404244, #232526);
  &_container {
  }

  &_wrapper {
    margin-bottom: 10rem;
  }

  &_item {
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(tab-land) {
      flex-direction: column;
    }
  }

  &_item > * {
    flex: 0 0 30%;
  }

  &_description {
    margin-right: 10rem;
    transform: translateY(100%);
    opacity: 0;
    transition: all 1.5s ease;

    @include respond(tab-land) {
      width: 70%;
      margin: auto;
    }

    &.solbox {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &_description_title {
    font-size: 2.5rem;
    color: white;
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 400;
  }

  &_description_paragraph {
    font-size: 1.5rem;
    margin: 1.5rem 0rem;
    color: #9fc3e5;
    font-weight: 300;
  }

  &_description_recap {
    font-size: 1.5rem;
    margin: 1.5rem 0rem;
    color: #bbbfc2;
  }

  &_description_eye_wrapper {
    text-align: center;

    @include respond(tab-land) {
      margin-bottom: 3rem;
    }
  }

  &_description_eye_container {
    display: inline-block;
    padding: 0.5rem 3rem;
    border: 1px solid #1f98eb;
    border-radius: 5px;
    transition: all 0.3s;
  }

  &_description_eye_container:hover {
    background-color: #1f98eb;
    border: 1px solid white;
  }

  &_description_eye_container:hover &_description_eye {
    fill: #fff;
  }

  &_description_eye {
    height: 2rem;
    width: 2rem;
    fill: #1f98eb;
    display: block;
  }

  &_image_container {
    transform: translateX(25%);
    opacity: 0;
    transition: all 1.5s ease;

    @include respond(tab-land) {
      width: 60%;
    }

    &.solbox {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &_image {
    width: 100%;
    transition: all 1s ease;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 1rem 2rem rgba(white, 0.1);
    }
  }
}
