@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    //600px
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }

  @if $breakpoint == phone-custom {
    //900px
    @media only screen and (max-width: 49em) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    //900px
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }

  @if $breakpoint == tab-portsol {
    //900px
    @media only screen and (max-width: 58.25em) {
      @content;
    }
  }

  //1200px
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }

  //1800px+
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    //1200px
    font-size: 56.25%; //1rem = 9px; 9px/16 = 56.25%
  }

  @include respond(tab-port) {
    //900px
    font-size: 50%; //1rem = 8px; 8px/16 = 50%
  }

  // @include respond(big-desktop) {
  //   font-size: 75%; //1rem = 12, 12/16 = 75%
  // }
}

body {
  // background: #333;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

@import './components/navbar/navbar.scss';
@import './components/showcase/showcase.scss';
@import './components/footer/footer.scss';
@import './components/svgSlider/SvgSlider.scss';
@import './components/stacked/stacked.scss';
@import './components/scss/button.scss';
@import './components/mouse/mouse.scss';
@import './components/display/display.scss';
// @import './components/skills/skill.scss';
@import './components/skillBox/skillbox.scss';
@import './components/icon/icon.scss';
// @import './components/work/work.scss';
@import './components/portfolio/portfolio.scss';
@import './components/contact/contact.scss';
@import './components/modal/modal.scss';

// .App {
//   background-image: url('./media/images/programmer.jpg');
//   background-size: cover;
//   background-position: center;
//   background-attachment: fixed;
//   background-repeat: no-repeat;
// }

// .stage_container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   overflow: hidden;
// }

.loading {
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  h1 {
    font-size: 4rem;
  }
}

.stage_1 {
  background-image: linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark);
  // background-image: linear-gradient(to right, lighten(black, 10%), lighten(black, 20%));
  // opacity: 0.5;
  // clip-path: polygon(0 0, 20% 0, 100% 100%, 0 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 6%, 45% 6%, 100% 65%, 100% 90%, 0% 90%);
  // height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  z-index: -1;
  // min-height: 1100px;

  @include respond(tab-land) {
    //1200px
    height: 112vh;
    clip-path: polygon(0% 0%, 100% 0%, 100% 6%, 45% 6%, 100% 65%, 100% 100%, 0% 100%);
  }
}

.mt-2rem {
  margin-top: 2rem;
}
.mt-4rem {
  margin-top: 4rem;
}

.mt-6rem {
  margin-top: 6rem;
}

.mt-18rem {
  margin-top: 18rem;
}

.mt-s {
  margin-top: 8rem;
}

.mx-1rem {
  margin-left: 1rem;
  margin-right: 1rem;
}

// .stage_1 {
//   background-image: linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark);
//   // opacity: 0.5;
//   // clip-path: polygon(0 0, 20% 0, 100% 100%, 0 100%);
//   clip-path: polygon(0 0, 5% 0, 5% 45%, 50% 100%, 95% 45%, 95% 0%, 100% 0%, 100% 100%, 0 100%);
//   height: 100vh;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   opacity: 1;
//   // z-index: -1;
// }

// .stage_2 {
//   background-image: linear-gradient(to right, $color-tertiary-dark, $color-tertiary-light);
//   // clip-path: polygon(0 0, 20% 0, 100% 100%, 0 100%);
//   // clip-path: polygon(0 0, 5% 0, 5% 45%, 50% 100%, 95% 45%, 95% 0%, 100% 0%, 100% 100%, 0 100%);
//   // clip-path: polygon(0 100%, 5% 100%, 5% 55%, 50% 0%, 95% 55%, 95% 100%);

//   clip-path: polygon(100% 100%, 95% 100%, 95% 55%, 50% 0%, 5% 55%, 5% 100%, 0% 100%, 0% 0%, 100% 0%);

//   height: 100vh;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   opacity: 0.4;
//   // z-index: -1;
// }

// .stage_3 {
//   background-image: linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark);
//   clip-path: polygon(0% 0%, 50% 0%, 45.8% 5%, 0% 5%);

//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   height: 100vh;

//   opacity: 1;
// }

// .stage_4 {
//   background-image: linear-gradient(to left, $color-tertiary-light, $color-tertiary-dark);
//   clip-path: polygon(100% 0%, 50% 0%, 54.2% 5%, 100% 5%);

//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   height: 100vh;

//   opacity: 1;
// }
