.contact {
  padding: 5rem 0;
  // background-color: #e4e4e4;

  &_container {
    display: flex;
    justify-content: center;
  }

  &_title {
    font-size: 2.25rem;
    letter-spacing: 0.1rem;
    text-align: center;
    color: #333;
  }

  &_flexbox {
    margin-top: 7rem;
    display: flex;
    justify-content: center;

    @include respond(tab-portsol) {
      flex-direction: column;
    }
  }

  &_flexbox &_container:first-child {
    margin-right: 10rem;
    @include respond(tab-portsol) {
      margin-right: 0;
    }
  }

  &_fixed_size {
    width: 400px;

    @include respond(tab-portsol) {
      width: 100%;
      margin: 0 3rem;
      margin-bottom: 5rem;
    }
  }

  &_group {
    position: relative;
  }

  &_spacer {
    margin-top: 6rem;
    @include respond(tab-portsol) {
      margin-top: 0;
    }
  }

  &_group_label {
    position: absolute;
    left: 0;
    top: 1rem;
    transition: all 0.5s ease;
    font-size: 1.5rem;
    pointer-events: none;
    transform: translateX(1rem);
    background-image: linear-gradient(to right, darken($color-tertiary-light, 15%), darken($color-tertiary-dark, 25%));
    color: transparent;
    background-clip: text;
  }

  &_group_input {
    width: 100%;
    padding: 1rem 1rem;
    box-shadow: none;
    outline: none;
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    background-color: white;
    position: relative;
    user-select: none;
    border: 1px solid transparent;
    outline: 1px solid rgba(black, 0.5);
    // border-bottom: 1px solid black;
  }

  &.disabled &_group_input {
    background-color: #ddd;
    cursor: not-allowed;
  }

  &_group_line_container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
  }

  &_group_line_container::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.25rem;
    width: 100%;
    background-color: $color-tertiary-dark;
    transform: translateX(-100%);
  }

  &_group_input:focus,
  &_group_input:not(:placeholder-shown) {
    outline: 1px solid $color-tertiary-light;
    border: 1px solid $color-tertiary-light;
  }

  // &_group_input:focus {
  //   outline: 1px solid $color-tertiary-light;
  //   border: 1px solid $color-tertiary-light;
  // }

  &_group_input:focus ~ &_group_line_container::after {
    transition: all 0.5s ease;
    transform: translateX(0%);
  }

  &_group_input:focus + &_group_label,
  &_group_input:not(:placeholder-shown) + &_group_label {
    // // transform: translateY(-160%);
    top: -3rem;
    transform: none;
    font-size: 2rem;
  }

  &_group_textarea_container {
    @include respond(tab-portsol) {
      width: 100%;
      margin: 0 3rem;
    }
  }

  &_group_textarea {
    resize: none;
    height: 15rem;
    width: calc(800px + 10rem);
    padding: 1rem 1rem;

    @include respond(tab-portsol) {
      width: 100%;
    }
  }

  &_button_flexbox {
    display: flex;
    justify-content: center;
  }
}
