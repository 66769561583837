.btn {
  display: inline-block;
  text-decoration: none;
  font-size: 2rem;
  padding: 1rem 3rem;
  color: #fff;
  // box-shadow: 0 1rem 1rem rgba(black, 0.1);
  transition: all 0.4s ease;
  border-radius: 5px;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;

  &_orange {
    background-color: #e84302;
    &:hover {
      background: #fff;
      color: #e84302;
      border: 1px solid #e84302;
    }
  }

  &_red {
    background-color: red;
    &:hover {
      background: #fff;
      color: red;
      border: 1px solid red;
    }
  }

  &_blue {
    background-color: $color-tertiary-light;
    // background-image: linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark);

    &:hover {
      background: #fff;
      color: $color-tertiary-light;
      border: 1px solid $color-tertiary-light;
    }
  }

  &_disabled {
    background-color: #222222;
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background-color: #222222;
      color: #fff;
      border: 1px solid transparent;
    }
  }
}
