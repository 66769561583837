// #skillbox {
//   height: 20rem;
// }

.skillbox {
  margin: auto;
  margin-top: 4.75rem;
  margin-bottom: 12rem;
  width: 60%;

  box-shadow: 0 1rem 4rem rgba(black, 0.4);

  border-radius: 5px;
  overflow: hidden;

  @include respond(tab-land) {
    width: 95%;
  }

  &_top {
    background: #63656b;

    height: 4rem;
    display: flex;
    align-items: center;
  }

  &_circle {
    margin-left: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
    border-radius: 50%;
  }

  &_circle_red {
    background-color: #ff5f57;
  }

  &_circle_yellow {
    background-color: #ffbd2e;
  }

  &_circle_green {
    background-color: #2ace42;
  }

  &_body {
    background-color: #282c34;
    padding: 4rem 3rem;
  }

  &_text_wrapper {
    // display: inline-block;
    display: block;
  }

  &_text {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;

    overflow: hidden;
    display: inline-block;

    position: relative;

    // max-width: initial;

    line-height: 1.7;
    letter-spacing: 0.1rem;
    word-spacing: 0.25rem;
  }

  &_text_green {
    color: #86cb42;
    position: relative;
    font-weight: 500;
  }

  &_text_normal {
    display: inline-block;
    // text-align: right;
    position: relative;
    // width: 145px;
    // margin-right: 1rem;
  }

  &_square {
    // height: 2.3rem;
    width: 0.9rem;
    background: white;
    position: absolute;
    right: 0;

    top: 0%;
    bottom: 0%;
    display: inline-block;
  }

  &_home {
    position: relative;
    display: inline-block;
    user-select: none;
  }

  opacity: 0;
}

.setwidthmargin {
  width: 145px;
  margin-right: 1rem;

  @include respond(tab-land) {
    width: 130px;
  }

  .skillbox_square {
    display: none;
  }
}

.display_none {
  display: none;
}

.skillbox_blinker {
  // position: unset;
  // background: red;
  animation: 1s skillbox_blinker linear infinite;
}
@keyframes skillbox_blinker {
  1% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
}

.solbox {
  .skillbox {
    // &_1 {
    //   animation: 2s skillbox_wide1 linear forwards;
    // }

    // &_square_1 {
    //   animation: 2s skillbox_cursor linear forwards;
    // }

    // &_2 {
    //   animation: 3s skillbox_wide2 linear forwards 2s;
    // }

    // &_square_2 {
    //   animation: 3s skillbox_cursor linear forwards 2s;
    // }

    // &_3 {
    //   animation: 2s skillbox_wide2 linear forwards 5s;
    // }

    // &_square_3 {
    //   animation: 2s skillbox_cursor linear forwards 5s;
    // }

    // &_square_3 {
    //   animation: 1.5s skillbox_blinker linear infinite 7s;
    // }

    animation: 1s skillbox_wrapper linear forwards;
  }
}

@keyframes skillbox_wrapper {
  0% {
    transform: translate(10%, 0%);
    opacity: 0;
  }

  70% {
    transform: translate(-2%, 0%);
    opacity: 1;
  }

  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

// @keyframes skillbox_wide1 {
//   0% {
//     visibility: visible;
//     width: 0%;
//   }
//   100% {
//     width: 100%;
//     // white-space: nowrap;
//     visibility: visible;
//     // background: red;
//   }
// }

// @keyframes skillbox_wide2 {
//   0% {
//     visibility: visible;
//     width: 0%;
//   }
//   100% {
//     width: 100%;
//     // white-space: nowrap;
//     visibility: visible;
//     // background: red;
//   }
// }

// @keyframes skillbox_wide3 {
//   0% {
//     visibility: visible;
//     width: 0%;
//   }
//   100% {
//     width: 100%;
//     // white-space: nowrap;
//     visibility: visible;
//     // background: red;
//   }
// }

// @keyframes skillbox_cursor {
//   99% {
//   }
//   100% {
//     visibility: hidden;
//   }
// }
