$opacity_level: 0.5;

.stacked {
  &_items_wrapper {
    // width: 120rem;
    // margin: auto;
    // height: 70rem;
    // background-color: white;
    // margin-bottom: 5rem;
    position: relative;
  }

  &_item_container3:nth-child(6) {
    // display: none;
  }

  &_item_container3 > * {
    opacity: $opacity_level;
  }

  &_item_container3 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // background-color: blue;
    :hover {
      // display: none;
      cursor: pointer;
      opacity: 1;
    }
  }

  &_item_container5 {
    // padding: 1rem;
    display: inline-block;
    // border: 2px solid white;

    // box-shadow: 2rem 2rem 4rem rgba(black, 0.2);
    // background-image: linear-gradient(to right, red, blue);

    // backface-visibility: hidden;

    transform: rotateZ(45deg) skewY(-15deg) skewX(-15deg);

    :hover {
      background-image: linear-gradient(red, blue);
    }
  }

  &_item {
    height: 12rem;
    width: 12rem;
  }
}

$functionZINDEX: 10;
$yValue: -100;
$min: 0;
$xAnimateDistance: 100;
$yAnimateDistance: 70;

$total_items: 12;
$scaler: 100 / $total_items;
$duration: 15s;
// $duration: 7s;

$differenceStack: 5;

$sink1: 100;
$sink2: -75;

$actual_items: 9;

$newAnimationStart: 100 / $total_items * $actual_items;
$newAnimationSecond: (100 - $newAnimationStart) / 3 + $newAnimationStart;
$newAnimationThird: (100 - $newAnimationStart) * 2 / 3 + $newAnimationStart;

@for $i from 1 through 10 {
  .item_slotA#{$i} {
    // z-index: #{$i} !important;
    z-index: $functionZINDEX;
  }

  .item_slotB#{$i} {
    // transform: matrix(1, 0, 0, 1, 0, $yValue);
    transform: scale(1) translate(0, $yValue * 0.1rem);
    animation: $duration item#{$i} infinite;
  }

  // @keyframes item#{$i} {
  $middle: $min + $scaler/2;
  $max: $min + $scaler;
  $yAnimateValue: $yValue + $yAnimateDistance;

  @keyframes item#{$i} {
    #{$min}% {
      // transform: matrix(1, 0, 0, 1, 0, $yValue);
      transform: scale(1) translate(0, $yValue * 0.1rem);
      opacity: $opacity_level;
    }

    // Switch Direction
    $xAnimateDistance: -$xAnimateDistance;

    #{$middle}% {
      // transform: matrix(1, 0, 0, 1, $xAnimateDistance, $yAnimateValue);
      transform: scale(1) translate($xAnimateDistance * 0.1rem, $yAnimateValue * 0.1rem);
      opacity: 1;
    }

    $inbetween: $max - 0.00001;

    #{$inbetween}% {
      // transform: matrix(1, 0, 0, 1, 0, $yValue);
      transform: scale(1) translate(0, $yValue * 0.1rem);

      opacity: 1;
    }

    #{$max}% {
      // transform: matrix(1, 0, 0, 1, 0, $yValue);
      transform: scale(1) translate(0, $yValue * 0.1rem);
      opacity: $opacity_level;
    }

    // Default
    #{$newAnimationStart}% {
      // transform: matrix(1, 0, 0, 1, 0, $yValue);
      transform: scale(1) translate(0, $yValue * 0.1rem);
      opacity: $opacity_level;
    }

    #{$newAnimationSecond}% {
      // transform: matrix(2, 0, 0, 2, 0, $sink2);
      transform: scale(1.5) translate(0, ($sink2 * 0.05rem));
      opacity: 1;
    }
    #{$newAnimationThird}% {
      // transform: matrix(1, 0, 0, 1, 0, $sink1);
      transform: scale(1) translate(0, $sink1 * 0.1rem);
      opacity: 1;
    }

    100% {
      // transform: matrix(1, 0, 0, 1, 0, $yValue);
      transform: scale(1) translate(0, $yValue * 0.1rem);
      opacity: $opacity_level;
    }

    $sink1: $sink1 + $differenceStack;
    $sink2: $sink2 + $differenceStack;
  }

  $functionZINDEX: $functionZINDEX - 1;
  $yValue: $yValue + 30;

  $min: $min + $scaler;
}

// Item 1 HTML
.item_slotC1 {
  background-color: orange;
}
// Item 2 CSS
.item_slotC2 {
  background-color: blue;
}
// Item 3 JAVASCRIPT
.item_slotC3 {
  background-color: yellow;
}
// Item 4 REACT
.item_slotC4 {
  background-color: teal;
}
// Item 5 NODEJS
.item_slotC5 {
  background-color: green;
}
// Item 6 MONGODB
.item_slotC6 {
  background-color: greenyellow;
}
// Item 7 EXPRESS
.item_slotC7 {
  background-color: brown;
}
// Item 8 PHP
.item_slotC8 {
  background-color: purple;
}
// Item 9 MYSQL
.item_slotC9 {
  background-color: black;
}
