.showcase {
  margin-top: 8rem;

  &_container {
    // min-height: 1500px;
    position: relative;
    padding-bottom: 10rem;
    // background-image: linear-gradient(to right top, rgba(#00545d, 0.1) 70%, rgba(#000729, 1)), url('../../media/images/background.png');
    // background-image: url('../../media/images/background.png');
    // background: linear-gradient(to right, rgba(#00545d, 0.5), rgba(#000729, 0.5)), url('../../media/images/background.png');
    background-position: left;
    z-index: 0;
  }

  &_design {
    background-image: linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark);
    // background-image: linear-gradient(to right, lighten(black, 10%), lighten(black, 20%));
    // opacity: 0.5;
    // clip-path: polygon(0 0, 20% 0, 100% 100%, 0 100%);
    // background-color: green;

    // background-image: linear-gradient(to right, lighten(red, 44%), darken(blue, 40%));

    background-image: url('../../media/images/bgs/rune10.jpg');
    clip-path: polygon(0% 0%, 100% 0%, 100% 0, 40% 0, 100% 70%, 100% 100%, 0% 100%);

    @include respond(tab-land) {
      // background-image: url('../../media/images/bgs/rune3.jpg');
      clip-path: none;
    }

    background-position: center;
    background-size: cover;

    // height: 100vh;
    // opacity: 1;
    // z-index: -5;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -5;
    pointer-events: none;
  }

  &_bubbles {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // display: flex;
    // align-items: flex-end;
    // justify-content: space-around;
    pointer-events: none;
    overflow: hidden;
  }

  &_bubble {
    width: 5rem;
    animation: bubble 12s linear infinite;
    transform: translateY(4vh);
    position: absolute;
    bottom: 0;
  }

  @keyframes bubble {
    0% {
      bottom: 0;
    }

    50% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      bottom: 110%;
    }
  }

  &_bubbles img:nth-child(1) {
    animation-delay: -6.66s;

    width: 2.5rem;
    left: 10%;
  }
  &_bubbles img:nth-child(2) {
    animation-delay: -12s;

    left: 20%;
  }
  &_bubbles img:nth-child(3) {
    width: 2.5rem;
    animation-delay: -4s;
    left: 30%;
  }
  &_bubbles img:nth-child(4) {
    animation-delay: -9.33s;
    left: 40%;
  }
  &_bubbles img:nth-child(5) {
    animation-delay: -1.33s;
    left: 50%;
  }
  &_bubbles img:nth-child(6) {
    width: 2.5rem;

    animation-delay: -10.66s;
    left: 60%;
  }
  &_bubbles img:nth-child(7) {
    width: 3.5rem;
    animation-delay: -5.33s;

    left: 70%;
  }
  &_bubbles img:nth-child(8) {
    width: 2rem;
    animation-delay: -8s;
    left: 80%;
  }
  &_bubbles img:nth-child(9) {
    animation-delay: -2.66s;
    left: 90%;
  }

  display: flex;
  justify-content: space-evenly;

  @include respond(tab-land) {
    //1200px
    flex-direction: column;
  }

  &_box {
    height: 63vh;
    flex: 0 0 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &_box1 {
    // background: red;
    display: flex;
    padding-top: 3rem;

    @include respond(tab-land) {
      //1200px
      margin-top: 15rem;
    }
  }
  &_box2 {
    // background: blue;
    // justify-content: center;

    @include respond(tab-land) {
      //1200px
      margin-top: 25rem;
      margin-bottom: 25rem;
    }
  }

  &_box1_image {
    height: 20rem;
    width: 20rem;
    border-radius: 50%;
    border: 2px solid black;
    box-shadow: 0 2rem 4rem rgba(black, 0.2);
  }

  &_box1_paragraph {
    font-size: 3rem;
    letter-spacing: 0.2rem;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    color: #fff;

    @include respond(tab-land) {
      // background: linear-gradient(to right, white, white 70%, black 71%);
      // color: transparent;
      // background-clip: text;
      // display: inline-block;
      // width: 50%;
      // background: linear-gradient(to right, #00545d, #000729);

      // -webkit-box-decoration-break: clone;
      // box-decoration-break: clone;

      // color: black;
      font-weight: 400;
    }

    @include respond(phone) {
      padding: 0 0.5rem;
    }
  }

  // &_box2 {
  //   background: blue;
  // }
}
