.display {
  display: flex;
  justify-content: center;

  &_title {
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    position: relative;

    background-image: linear-gradient(to right, darken($color-tertiary-light, 15%), darken($color-tertiary-dark, 25%));
    color: transparent;
    background-clip: text;
    padding-bottom: 2rem;

    border-bottom: 2px solid darken($color-tertiary-light, 15%);

    &::before {
      content: '';
      height: 3px;
      width: 20px;
      background-image: linear-gradient(to right, darken($color-tertiary-light, 25%), darken($color-tertiary-dark, 15%));
      display: inline-block;
      position: absolute;
      top: 30%;
      left: -25%;
      transform: translateY(-50%);
    }

    &::after {
      content: '';
      height: 3px;
      width: 20px;
      background-image: linear-gradient(to right, darken($color-tertiary-light, 15%), darken($color-tertiary-dark, 25%));
      display: inline-block;
      position: absolute;
      top: 30%;
      right: -25%;
      transform: translateY(-50%);
    }
  }
}

.display {
  &_container {
  }

  &1 {
    margin-top: 7rem;
  }

  &2 {
    padding-top: 4rem;
    background-image: linear-gradient(to right, #404244, #232526);
    padding-bottom: 1rem;
  }

  &2 &_title {
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    position: relative;

    background-image: linear-gradient(to right, darken(white, 15%), darken(white, 25%));
    color: transparent;
    background-clip: text;
    padding-bottom: 2rem;

    border-bottom: 2px solid darken(white, 15%);

    &::before {
      content: '';
      height: 3px;
      width: 20px;
      background-image: linear-gradient(to right, darken(white, 15%), darken(white, 25%));
      display: inline-block;
      position: absolute;
      top: 30%;
      left: -25%;
      transform: translateY(-50%);
    }

    &::after {
      content: '';
      height: 3px;
      width: 20px;
      background-image: linear-gradient(to right, darken(white, 25%), darken(white, 15%));
      display: inline-block;
      position: absolute;
      top: 30%;
      right: -25%;
      transform: translateY(-50%);
    }
  }

  &3 {
    padding-top: 4rem;
  }
}
