.svg-slider {
  &_primary {
    // margin-top: 12rem;
    padding-bottom: 3.2rem;
    // background-color: #222222;
  }

  &__container {
    position: relative;
    // background: white;
    // max-width: 120rem;
    margin: auto;
    height: 6.4rem;
    overflow: hidden;
  }

  &__item {
    display: inline-block;
    margin: 1.6rem 3.2rem;
    transition: all 1s ease;
    &:hover {
      cursor: pointer;
      transform: scale(1.5);
    }
  }

  &_items__container {
    display: flex;
  }

  &__container_negative_wrapper {
    transform: translateX(-86.4rem);
  }

  &_items__container-1 {
    animation: 20s firstBar linear infinite;
    // animation: 0.01s firstBar linear forwards;
  }

  &_items__container-2 {
    animation: 20s secondBar linear infinite;
    // animation: 0.01s secondBar linear forwards;
  }

  &_items__container-3 {
    animation: 20s thirdbar linear infinite;
    // animation: 0.01s thirdbar linear forwards;
  }

  @keyframes firstBar {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-86.4rem);
    }
  }

  @keyframes secondBar {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(86.4rem);
    }
  }

  @keyframes thirdbar {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-96rem);
    }
  }
}

// SVG ICON
.sol {
  height: 3.2rem;
  width: 3.2rem;
  display: inline-block;
}
