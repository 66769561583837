.footer {
  // background: navy;
  padding-top: 3.2rem;

  &_icon_container {
    display: flex;
    justify-content: center;
  }

  &_title_1 {
    font-size: 3rem;
    text-align: center;
    margin: 2rem 0;
    letter-spacing: 0.1rem;
    margin-bottom: 4rem;
  }

  &_title_2 {
    font-size: 1.5rem;
    text-align: center;
    margin: 3rem 0;
    letter-spacing: 0.05rem;
    font-weight: 900;
  }

  &_icon_wrapper {
    margin: 0 0.5rem;
    padding: 2rem 2rem;
    border-radius: 50%;
    transition: all 0.5s ease;
    border: 1px solid transparent;
    display: block;
    transform: rotateZ(45deg) skewY(-15deg) skewX(-15deg);
  }

  &_icon_wrapper_github:hover {
    border: 1px solid navy;
    background-color: darken(red, 35%);
    transform: none;

    & .fill_github {
      fill: white;
    }
  }

  &_icon_wrapper_linkedin:hover {
    border: 1px solid navy;
    background-color: darken(red, 35%);
    transform: none;

    & .fill_linkedin {
      fill: white;
    }
  }

  &_icon {
    display: block;
    height: 5rem;
    width: 5rem;
  }
}

.fill_github {
  fill: #333333;
  // &:hover {
  //   fill: red;
  //   // border-radius: 50%;
  //   border: 1px solid red;
  // }
}

.fill_linkedin {
  fill: #0e76a8;
}
