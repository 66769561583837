.navbar {
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin: 0 4rem;
  align-items: center;
  z-index: 1;

  height: 7rem;
  padding: 4rem 0;

  @include respond(phone) {
    margin: 0 1rem;
    flex-direction: column;
    height: unset;
    padding: 0rem;
    justify-content: flex-start;
  }

  &_wrapper {
    // background-image: linear-gradient(to right, darken($color-tertiary-light, 15%), lighten($color-tertiary-dark, 15%));
    position: fixed;

    @include respond(phone-custom) {
      position: relative;
      margin-bottom: -8rem;
    }

    left: 0;
    top: 0;
    right: 0;
    z-index: 15;

    // background-image: linear-gradient(to right, lighten(black, 10%), lighten(black, 20%));
    transition: background-color 1.5s ease;

    // background-image: linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark);
    // background-color: $color-tertiary-light;

    background-image: linear-gradient(to right, #00545d, #000729);
    background-color: rgba(black, 0.7);

    @include respond(tab-land) {
      //1200px
      // background-image: linear-gradient(#00c2df, #00545d);
      box-shadow: 0 2rem 4rem rgba(black, 0.4);
    }

    // background-image: linear-gradient(to right, darken(green, 40%), darken(red, 40%), darken(green, 40%));
    // background-image: linear-gradient(to right, lighten(red, 44%), darken(blue, 40%));
    // background-color: darken(blue, 40%);
    &.solbox {
      background-image: none;
      background-color: darken(red, 47%);
      opacity: 0.8;
    }

    @include respond(phone) {
      padding-top: 1rem;
      padding-bottom: 2.5rem;
    }
  }

  // &_logo {
  //   font-size: 3rem;
  //   letter-spacing: -0.2rem;

  //   color: $color-grey-light-1;
  //   font-weight: 500;
  //   // padding-left: 12.5rem;

  //   // background-image: linear-gradient(to right, darken($color-tertiary-light, 15%), lighten($color-tertiary-dark, 15%));

  //   // background-clip: text;
  //   // color: transparent;
  // }
  &_logo {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 6rem;
    cursor: pointer;
  }

  &_links {
    display: flex;
    justify-content: space-between;
  }

  &_link {
    text-decoration: none;
    display: flex;
    align-items: center;

    font-size: 2rem;
    // padding: 3rem;
    color: $color-grey-light-1;

    &:not(:last-child) {
      margin-right: 4rem;
    }

    transition: all 0.4s ease;

    border: 1px solid #fff;
    border-radius: 20px;
    padding: 1rem 3.5rem;

    &:hover {
      color: $color-secondary-light;
      border-color: $color-secondary-light;
    }
  }
}
