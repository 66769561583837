.mouse {
  margin: auto;
  height: 5.5rem;
  width: 3.5rem;
  border: 3px solid white;
  border-radius: 1.5rem;

  transition: all 0.3s ease-out;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: #e84302;
  }

  &:hover > &_dot {
    background-color: black;
  }

  &_dot {
    height: 0.9rem;
    width: 0.9rem;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 25%;

    border-radius: 3px;

    transform: translate(-50%, -50%);

    animation: 3s mouse_dot infinite;
  }
}

@keyframes mouse_dot {
  0% {
    top: 25%;
  }

  20% {
    top: 70%;
    width: 0.6rem;
  }

  40% {
    width: 0.9rem;
    top: 25%;
  }

  100% {
  }
}
