.icon {
  background-color: #222222;
  padding: 4rem 0rem;

  &_container {
    display: flex;
    justify-content: center;
    transform: translateY(100%);
    opacity: 0;
    transition: all 1.5s ease;
    flex-wrap: wrap;
  }

  &_item {
    margin: 3rem 3rem;
  }

  .stacked_item {
    height: 8rem;
    width: 8rem;
  }

  .solbox {
    transform: translateY(0);
    opacity: 1;
  }
}
